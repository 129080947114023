import React, { Component } from 'react';
import s from './LogoSchauHinBlock.module.css'
import {StaticQuery, graphql} from 'gatsby'

  
export default class logoSchauHin extends Component {
    render() {
      return(

        <StaticQuery
        query={graphql`
        query MyQueryLogoSchauHin {
           logoSchauHin: file(relativePath: { eq: "LogoSchauHin.svg" }){
          publicURL
           } 
        }
        `}

        render={d =>  {
          return (
             
                  <img className={s.logoSchauHin} src={d.logoSchauHin.publicURL} alt="logoSchauHin"/>
           
        )}
      }
      />

      )
    }
  }
  
 